import { getDeviceId, getUserAgent } from 'functions/general'
import { useMainStore } from 'state/main'
import { IContainer, ICredentials } from 'types/general'
import { mapToArray } from 'utils/maps'
import { getCredentials } from '../functions/general'
import { jwtHandler, scannerApiAxios } from './setup'

// Figure out server url - if dev of prod
export const server = () =>
  window.location.hostname === 'localhost'
    ? 'http://localhost:3400/api'
    : window.location.origin + '/api'

/* JWT API CALLS */

export async function postRegister(qr_id: string) {
  const user_agent = getUserAgent()
  const device_id = getDeviceId()

  return jwtHandler(() =>
    scannerApiAxios.post<{ data: ICredentials }>(
      `${server()}/v1/register`,
      { qr_id, device_id, user_agent },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
  ).then((res) => res?.data)
}

/* NB! SHOULD NOT USE JWTHANDLER */
export async function postRenew() {
  const jwt = getCredentials()?.jwt
  const user_agent = getUserAgent()
  const device_id = getDeviceId()

  return scannerApiAxios
    .post<{ data: ICredentials }>(
      `${server()}/v1/renew`,
      { device_id, user_agent },
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Basic ${jwt}`,
        },
      }
    )
    .then((res) => res.data.data)
}

/* /JWT API CALLS */

export const getContainer = (containerId: string) => {
  const jwt = getCredentials()?.jwt

  return jwtHandler(() =>
    scannerApiAxios.get<{ data: IContainer }>(
      `${server()}/v1/container/latest/${containerId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Basic ${jwt}`,
        },
      }
    )
  ).then((res) => res.data)
}

export const getFillings = () => {
  const jwt = getCredentials()?.jwt

  return jwtHandler(() =>
    scannerApiAxios.get<{ data: { id: number; name: string }[] }>(
      `${server()}/v1/filling`,
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Basic ${jwt}`,
        },
      }
    )
  ).then((res) => res.data)
}

export const putContainersDelivery = () => {
  const jwt = getCredentials()?.jwt
  const device_id = getDeviceId()

  const { orderId, containers } = useMainStore.getState()
  const conts = containers ? mapToArray(containers).map((c) => c.id) : null

  if (orderId && conts?.length) {
    return jwtHandler(() =>
      scannerApiAxios.put<{ data: undefined }>(
        `${server()}/v1/container/delivery`,
        { order_id: orderId, containers: conts, device_id },
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Basic ${jwt}`,
          },
        }
      )
    ).then((res) => res.data)
  } else {
    return new Promise<undefined>((res, rej) =>
      rej('Kunne ikke finne beholdere på ordren')
    )
  }
}

export const putContainersReturn = () => {
  const jwt = getCredentials()?.jwt
  const device_id = getDeviceId()

  const { containers } = useMainStore.getState()
  const conts = containers ? mapToArray(containers).map((c) => c.id) : null

  if (conts?.length) {
    return jwtHandler(() =>
      scannerApiAxios.put<{ data: undefined }>(
        `${server()}/v1/container/return`,
        { containers: conts, device_id },
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Basic ${jwt}`,
          },
        }
      )
    ).then((res) => res.data)
  } else {
    return new Promise<undefined>((res, rej) =>
      rej('Ingen beholdere satt til retur')
    )
  }
}

export const putContainersPreRecondition = () => {
  const jwt = getCredentials()?.jwt
  const device_id = getDeviceId()

  const { containers } = useMainStore.getState()
  const conts = containers ? mapToArray(containers).map((c) => c.id) : null

  if (conts?.length) {
    return jwtHandler(() =>
      scannerApiAxios.put<{ data: undefined }>(
        `${server()}/v1/container/prerecondition`,
        { containers: conts, device_id },
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Basic ${jwt}`,
          },
        }
      )
    ).then((res) => res.data)
  } else {
    return new Promise<undefined>((res, rej) =>
      rej('Ingen beholdere satt til rekondisjonering')
    )
  }
}

export const putContainersWash = () => {
  const jwt = getCredentials()?.jwt
  const device_id = getDeviceId()

  const { containers } = useMainStore.getState()
  const conts = containers ? mapToArray(containers).map((c) => c.id) : null

  if (conts?.length) {
    return jwtHandler(() =>
      scannerApiAxios.put<{ data: undefined }>(
        `${server()}/v1/container/wash`,
        { containers: conts, device_id },
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Basic ${jwt}`,
          },
        }
      )
    ).then((res) => res.data)
  } else {
    return new Promise<undefined>((res, rej) =>
      rej('Ingen beholdere satt til rekondisjonert')
    )
  }
}

export const putContainersFill = () => {
  const jwt = getCredentials()?.jwt
  const device_id = getDeviceId()

  const { containers, fillId } = useMainStore.getState()
  const conts = containers ? mapToArray(containers).map((c) => c.id) : null

  if (conts?.length) {
    return jwtHandler(() =>
      scannerApiAxios.put<{ data: undefined }>(
        `${server()}/v1/container/filling`,
        { containers: conts, filling_id: fillId, device_id },
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Basic ${jwt}`,
          },
        }
      )
    ).then((res) => res.data)
  } else {
    return new Promise<undefined>((res, rej) =>
      rej('Ingen beholdere satt til fylling')
    )
  }
}

export const putDamagedContainer = ({
  damage_id,
  damage_message,
  image_urls,
}: {
  damage_id: number
  damage_message: string | undefined
  image_urls: Promise<
    { type: string; extention: string; file: string }[] | null
  >
}) => {
  const jwt = getCredentials()?.jwt
  const device_id = getDeviceId()

  const { containers } = useMainStore.getState()
  const container_ids = containers
    ? mapToArray(containers).map((c) => c.id)
    : null

  if (container_ids) {
    return image_urls
      .then((images) =>
        jwtHandler(() =>
          scannerApiAxios.put<{ data: undefined }>(
            `${server()}/v1/container/damage`,
            {
              damage_id,
              container_ids,
              device_id,
              ...(damage_message ? { damage_message } : {}),
              ...(images ? { image_urls: images } : {}),
            },
            {
              headers: {
                'Content-Type': 'application/json',
                authorization: `Basic ${jwt}`,
              },
            }
          )
        )
      )
      .then((res) => res.data)
  } else {
    return new Promise<undefined>((res, rej) =>
      rej('Kunne ikke finne beholderen')
    )
  }
}
