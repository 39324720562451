import { CloseIcon } from 'resources/icons/CloseIcon'
import styles from './ClearButton.module.css'

export const ClearButton = ({
  onClick,
  className,
}: {
  onClick?: () => void
  className?: string
}) => {
  return (
    <span className={`${styles.closeButton} ${className}`} onClick={onClick}>
      <CloseIcon color="var(--text)" />
    </span>
  )
}
