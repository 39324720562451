interface IconProps {
  width?: number
  height?: number
  color?: string
  opacity?: string
  style?: React.CSSProperties
}

export const CloseIcon = ({
  width = 15,
  height = 15,
  color = 'black',
  opacity = '0.65',
  style,
}: IconProps) => {
  return (
    <svg
      style={style}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L15 15M15 1L1 15"
        stroke={color}
        strokeOpacity={opacity}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}
