import { QRScanner } from 'components/QRScanner/QRScanner'
import { addQrUserToGlobalState } from 'functions/camera/orkla'
import { useMainStore } from 'state/main'
import { IPage } from 'types/page'

export const CameraUser = ({ route, text }: { route: IPage; text: string }) => {
  const { setPage } = useMainStore()
  const onQrScan = (qrUrl: string) => {
    return addQrUserToGlobalState(qrUrl).then((isOk) => {
      setPage(route)
      return isOk
    })
  }
  return (
    <div className="appView cols">
      <div className="view">
        <div className="rows rows-sm2">
          <QRScanner qrResultFunction={onQrScan} />
        </div>
        <div className="text-center">
          <p>{text}</p>
        </div>
      </div>
    </div>
  )
}
