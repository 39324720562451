import { ClearButton } from 'components/ClearButton/ClearButton'
import React from 'react'
import { useMainStore } from 'state/main'
import { IContainer } from 'types/general'
import { mapToArray } from 'utils/maps'
import styles from './ContainerHandlerList.module.css'

export const ContainerHandlerList = ({
  removePermission = true,
  lastRemovedItem,
}: {
  removePermission?: boolean
  lastRemovedItem?: (qr: string) => void
}) => {
  const { containers, removeContainer } = useMainStore()
  const arrayContainers = containers ? mapToArray(containers) : []

  const remove = (c: IContainer) => {
    lastRemovedItem && lastRemovedItem(c.qr)
    removeContainer(c)
  }

  return (
    <div className={styles.containerHandlerList}>
      {arrayContainers.map((c, i) => (
        <React.Fragment key={`container_list_${i}`}>
          <span>{c.qr}</span>
          <span>{c.size} L</span>
          <span>{c.fill_name}</span>
          {removePermission ? (
            <ClearButton
              onClick={() => remove(c)}
              className="grid-self-right"
            />
          ) : (
            <span></span>
          )}
        </React.Fragment>
      ))}
    </div>
  )
}
