import { SVGProps } from 'react'

export const CheckIcon = ({
  fill,
  width,
  height,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width={width || 25}
      height={height || 25}
      viewBox="0 20 130 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="matrix(1,0,0,1,-2.9214,4.59785)">
        <path
          d="M59.774,111.349L22.068,73.644L29.962,65.751L59.774,95.562L125.881,29.455L133.774,37.348L59.774,111.349Z"
          fill={fill || 'black'}
          style={{
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 1.5,
          }}
        />
      </g>
    </svg>
  )
}
