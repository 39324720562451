export const fileReader = (
  file: File
): Promise<{ type: string; extention: string; file: string } | null> => {
  if (file) {
    return new Promise<{ type: string; extention: string; file: string }>(
      (res, rej) => {
        const reader = new FileReader()

        const fileExtention = (file.name.match(/\.[0-9a-z]+$/i) as string[])[0]

        reader.onload = (evt) =>
          evt.target?.result
            ? res({
                type: file.type,
                extention: fileExtention,
                file: evt.target.result as string,
              })
            : rej('Problemer med å dekode en av filene')

        reader.onerror = (evt) => {
          rej('Problemer med å lese en av filene.')
        }

        reader.readAsDataURL(file)
      }
    )
  } else {
    return new Promise<null>((res) => res(null))
  }
}

export const filesToFileArray = (
  files: FileList | undefined | null
): Promise<{ type: string; extention: string; file: string }[] | null> =>
  files
    ? Promise.all(Array.from(files).map(fileReader))
        .then((fs) => fs.filter((f) => f))
        .then((fs) => (fs?.length ? fs : null))
    : new Promise<any>((res) => res(null))
