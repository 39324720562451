import {
  getFillings,
  putContainersDelivery,
  putContainersFill,
  putContainersPreRecondition,
  putContainersReturn,
  putContainersWash,
} from 'api/fetch'
import { IUseMainStoreInputUpdates, IUseMainStoreInputValues } from 'state/main'
import { match } from 'ts-pattern'
import { IRegTypeWithoutDamage } from 'types/general'

export type IOverviewSetupConfig = {
  components: {
    title?: string
    component:
      | {
          type: 'number'
          placeholder?: string
          state: keyof IUseMainStoreInputValues
          setState: keyof IUseMainStoreInputUpdates
          required: boolean
        }
      | {
          type: 'text'
          placeholder?: string
          state: keyof IUseMainStoreInputValues
          setState: keyof IUseMainStoreInputUpdates
          required: boolean
        }
      | {
          type: 'select'
          options: () => Promise<{ id: number; name: string }[]>
          state: keyof IUseMainStoreInputValues
          setState: keyof IUseMainStoreInputUpdates
          required: boolean
        }
      | {
          type: 'containerHandlerList'
          editable?: boolean
          required: boolean
        }
  }[]
}

export const getOverviewSetup = (
  regType: IRegTypeWithoutDamage
): IOverviewSetupConfig =>
  match(regType)
    .with('RETURN', () => ({
      components: [
        {
          title: 'Returnerte beholdere',
          component: {
            type: 'containerHandlerList' as const,
            required: true,
          },
        },
      ],
    }))
    .with('PRERECONDITION', () => ({
      components: [
        {
          title: 'Til rekondisjonering',
          component: {
            type: 'containerHandlerList' as const,
            required: true,
          },
        },
      ],
    }))
    .with('WASH', () => ({
      components: [
        {
          title: 'Rekondisjonerte beholdere',
          component: {
            type: 'containerHandlerList' as const,
            required: true,
          },
        },
      ],
    }))
    .with('FILL', () => ({
      components: [
        {
          title: 'Fylling',
          component: {
            type: 'select' as const,
            options: getFillings,
            state: 'fillId' as keyof IUseMainStoreInputValues,
            setState: 'setFillId' as keyof IUseMainStoreInputUpdates,
            required: true,
          },
        },
        {
          title: 'Fylte beholdere',
          component: {
            type: 'containerHandlerList' as const,
            required: true,
          },
        },
      ],
    }))
    .with('DELIVERY', () => ({
      components: [
        {
          title: 'Ordrenummer',
          component: {
            type: 'text' as const,
            state: 'orderId' as keyof IUseMainStoreInputValues,
            setState: 'setOrderId' as keyof IUseMainStoreInputUpdates,
            required: true,
          },
        },
        {
          title: 'Fylte beholdere',
          component: {
            type: 'containerHandlerList' as const,
            required: true,
          },
        },
      ],
    }))
    .exhaustive()

export const getOverviewApiFunc = (regType: IRegTypeWithoutDamage) =>
  match(regType)
    .with('RETURN', putContainersReturn)
    .with('PRERECONDITION', putContainersPreRecondition)
    .with('WASH', putContainersWash)
    .with('FILL', putContainersFill)
    .with('DELIVERY', putContainersDelivery)
    .exhaustive()
