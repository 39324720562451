import { useMainStore } from 'state/main'
import logoCircle from '../../resources/images/logos/carrot-logosymbol-bg.svg'
import styles from './Header.module.css'

export const Header = () => {
  const status = useMainStore((state) => state.credentials?.name)

  return (
    <header className={styles.header}>
      <div className={styles.logoContainer}>
        <img src={logoCircle} alt="WasteIQ" />
      </div>
      <div className={styles.storeContainer}>
        <div className={`${styles.store} ${!status ? styles.alert : ''}`}>
          {status || 'Ikke registrert'}
        </div>
      </div>
      {/* <div className={styles.toggleThemeIcon}>
        <DarkModeIcon width="1.5rem" height="1.5rem" />
      </div> */}
    </header>
  )
}
