import { useEffect, useRef, useState } from 'react'
import { QrReader } from 'react-qr-reader'
import { FlipIcon } from 'resources/icons/FlipIcon'
import { beep } from 'resources/sounds/beep'
import styles from './QRScanner.module.css'

/**
 *
 * @param qrResultFunction Callback for getting the scanned qr code
 * @returns
 */
export const QRScanner = ({
  qrResultFunction,
  removeLastContainerState,
}: {
  qrResultFunction: (qr: string) => Promise<boolean>
  removeLastContainerState?: string | undefined
}) => {
  const prevQrValue = useRef<string>()
  const [flipCam, setFlipCam] = useState<boolean>(false)
  const [borderColor, setBorderColor] = useState<boolean>()
  const flip = () => setFlipCam(!flipCam)

  /** Setting border around camera to display if qr was ok or not */
  const cameraBorderColor = (isOk: boolean) => {
    setBorderColor(isOk)
    setTimeout(() => setBorderColor(undefined), 1000)
  }

  const onUpdate = (
    result?: { getText: () => string } | undefined | null,
    error?: Error | undefined | null,
    codeReader?: any | undefined
  ) => {
    if (result) {
      const qrStr = result.getText()
      if (qrStr !== prevQrValue.current) {
        qrResultFunction(result.getText())
          .then(cameraBorderColor)
          .then(() => beep())
          .catch((err) => {
            console.error(err)
            //setError()
          })
        prevQrValue.current = qrStr
      }
    }
  }

  useEffect(() => {
    if (removeLastContainerState) {
      prevQrValue.current = undefined
    }
  }, [removeLastContainerState])

  return (
    <div className={`${styles.newCamera}`}>
      <div className={`f4 ${styles.loadingCamera}`}>Laster kamera ..</div>
      <QrReader
        onResult={onUpdate}
        constraints={{ facingMode: flipCam ? 'user' : 'environment' }}
        ViewFinder={() => <div></div>}
        scanDelay={200}
        containerStyle={{
          position: 'relative',
          zIndex: 2,
          borderRadius: 'var(--border-radius)',
        }}
        videoContainerStyle={{
          width: '100%',
          paddingTop: 'initial',
          overflow: 'initial',
          position: 'relative',
          borderRadius: 'var(--border-radius)',
        }}
        videoStyle={{
          top: 'initial',
          left: 'initial',
          width: '100%',
          height: 'initial',
          display: 'initial',
          overflow: 'initial',
          position: 'initial',
          margin: '0 auto',
          border: `${
            borderColor
              ? '4px solid var(--success)'
              : borderColor === false
              ? '4px solid var(--danger)'
              : '4px solid transparent'
          }`,
          borderRadius: 'var(--border-radius)',
        }}
      />

      <button
        className="clear"
        onClick={flip}
        style={{
          position: 'absolute',
          right: '0rem',
          marginTop: '-4.5rem',
          zIndex: 3,
        }}
      >
        <FlipIcon
          width={36}
          height={36}
          style={{
            backgroundColor: 'white',
            padding: '0.45rem',
            boxShadow: '2px 2px 0.5rem rgba(0,0,0,0.5)',
            borderRadius: '50%',
          }}
        />
      </button>
    </div>
  )
}
