import { useMainStore } from 'state/main'

export const ErrorPopup = () => {
  const { error, setError } = useMainStore()
  return (
    <div className="popupWrapper">
      <div className="popupContainer">
        <h1>Obs!</h1>
        <p>{error}</p>
        <button className="w100" onClick={() => setError(null)}>
          Ok
        </button>
      </div>
    </div>
  )
}
