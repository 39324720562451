import { useMainStore } from '../state/main'
import { ICredentials } from '../types/general'

export function urlParams() {
  return new URLSearchParams(window.location.search)
}

export const getParams = (urlStr: string, param: string) => {
  const url = new URL(urlStr)
  return url.searchParams.get(param)
}

export const windowPathnameAsArray = () =>
  window.location.pathname.split('/').filter((a) => a !== '')

export function getUserAgent() {
  return navigator.userAgent
}

export function getDeviceId() {
  let deviceId = localStorage.getItem('device_id')
  if (!deviceId) {
    deviceId = Math.random().toString(36).substring(2)
    localStorage.setItem('device_id', deviceId)
  }
  return deviceId
}

export const getLocalStorage = (
  key: string
): { [key: string]: string } | null => {
  const token = localStorage.getItem(key)
  if (token) {
    return JSON.parse(token)
  }
  return null
}

export function setLocalStorage(key: string, value: any) {
  localStorage.setItem(key, JSON.stringify(value))
}

export function removeFromLocalStorage(key: string) {
  localStorage.removeItem(key)
}

export const getCredentials = () =>
  getLocalStorage('credentials') as ICredentials | null

export function storeCredentials(creds: ICredentials) {
  // Set to localstorage
  setLocalStorage('credentials', creds)
  // Set to global state
  const mainStore = useMainStore.getState()
  mainStore.setCredentials(creds)

  return creds
}

export const clearState = () => {
  const { setContainers, setOrderId, setRegType, setFillId, clearDamageState } =
    useMainStore.getState()
  setContainers(null)
  setOrderId(null)
  setFillId(null)
  setRegType(null)
  clearDamageState()
}

export const clearUser = () => {
  const { setCredentials } = useMainStore.getState()
  removeFromLocalStorage('credentials')
  setCredentials(null)
}

export const logoutUser = () => {
  const setPage = useMainStore.getState().setPage

  clearUser()
  clearState()
  setPage('/')
}
