import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import './index.css'
import reportWebVitals from './reportWebVitals'
import './styles/fonts.css'
import './styles/theme.css'
import './styles/styles.css'
import './styles/camera.css'
import './styles/orkla.css'

const Index = () => {
  //const setError = useMainStore((state) => state.setError)

  return (
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Index />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
