import {
  damageReducer,
  DamageReducerAction,
  DamageState,
} from 'pages/OverviewDamage/functions'
import create from 'zustand'
import { IContainer, ICredentials, IRegType } from '../types/general'
import { IPage } from '../types/page'

export type IUseMainStoreInputValues = {
  orderId: string | null
  fillId: string | null
}

export type IUseMainStoreValues = IUseMainStoreInputValues & {
  credentials: ICredentials | null
  page: IPage
  regType: IRegType | null
  containers: Map<string, IContainer> | null
  damageState: DamageState
  error: string | null
  dialog: {
    title: string
    msg: string
    comfirmText?: string
    cancelText?: string
  } | null
}

export type IUseMainStoreInputUpdates = {
  setOrderId: (_orderId: string | null) => void
  setFillId: (_fillId: string | null) => void
}

export type IUseMainStoreUpdates = IUseMainStoreInputUpdates & {
  setContainers: (_containers: Map<string, IContainer> | null) => void
  addContainer: (_container: IContainer) => void
  removeContainer: (_container: IContainer) => void
  setDamageState: ({ type, payload }: DamageReducerAction) => void
  clearDamageState: () => void
  setCredentials: (_credentials: ICredentials | null) => void
  setPage: (_page: IPage) => void
  setError: (_error: string | null) => void
  setDialog: (
    _dialog: {
      title: string
      msg: string
      comfirmText?: string
      cancelText?: string
    } | null
  ) => void
  setRegType: (_regType: IRegType | null) => void
}

// Test1
export type IUseMainStore = IUseMainStoreValues & IUseMainStoreUpdates

// Gloabl state - React hook
export const useMainStore = create<IUseMainStore>((set) => ({
  credentials: null,
  page: '/',
  orderId: null,
  containers: null,
  error: null,
  dialog: null,
  regType: null,
  fillId: null,
  damageState: {
    damage: undefined,
    desc: undefined,
    files: undefined,
    filesCount: undefined,
  },
  setContainers: (_containers) => set(() => ({ containers: _containers })),
  addContainer: (_container) =>
    set(({ containers }) => ({
      containers: containers
        ? containers.set(_container.qr, _container)
        : new Map().set(_container.qr, _container),
    })),
  removeContainer: (_container) =>
    set(({ containers }) => ({
      containers: containers
        ? [containers.delete(_container.qr)].map(() => containers)[0]
        : containers,
    })),
  setCredentials: (_credentials) => set(() => ({ credentials: _credentials })),
  setPage: (_page) => set(() => ({ page: _page })),
  setError: (_error) => set(() => ({ error: _error })),
  setDialog: (_dialog) => set(() => ({ dialog: _dialog })),
  setOrderId: (_orderId) => set(() => ({ orderId: _orderId })),
  setRegType: (_regType) => set(() => ({ regType: _regType })),
  setFillId: (_fillId) => set(() => ({ fillId: _fillId })),
  setDamageState: (action) =>
    set(({ damageState }) => ({
      damageState: damageReducer(damageState, action),
    })),
  clearDamageState: () =>
    set(() => ({
      damageState: {
        damage: undefined,
        desc: undefined,
        files: undefined,
        filesCount: undefined,
      },
    })),
}))
