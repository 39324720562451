import { BottomSection } from 'components/BottomSection/BottomSection'
import { CheckIcon } from 'resources/icons/CheckIcon'
import { useMainStore } from 'state/main'
import { IPage } from 'types/page'

export const Completed = ({ route }: { route: IPage }) => {
  const setPage = useMainStore((state) => state.setPage)

  return (
    <div className="appView cols">
      <div
        className="view middle"
        style={{ position: 'absolute', width: '100%', height: '80%' }}
      >
        <div className="rows rows-lg" style={{ width: '100%' }}>
          <div className="rows rows-lg center">
            <div className="columns center">
              <h1 className="f1">Fullført</h1>
              <CheckIcon fill="var(--fucales)" width={32} height={32} />
            </div>
          </div>
          <BottomSection noPadding>
            <div className="rows nospace">
              <button className="w100 nospace" onClick={() => setPage(route)}>
                Ok
              </button>
            </div>
          </BottomSection>
        </div>
      </div>
    </div>
  )
}
