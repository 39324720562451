import React from 'react'
import styles from './Radio.module.css'

export const Radio = ({ id }: { id: string }) => {
  return (
    <div className={styles.radio}>
      <input type="radio" name={`radio-${id}`} />
      <label htmlFor={`radio-${id}`}>Coffee</label>
    </div>
  )
}

export const RadioGroup = ({
  radios,
  state,
  setState,
  disabled,
}: {
  radios: { label: string; value: string }[]
  state: string | undefined
  setState: (val: string) => void
  disabled?: boolean
}) => (
  <div className={styles.radio}>
    {radios.map((r, i) => (
      <div
        key={`radios-${r.label}-${i}`}
        className={styles.radiogroup}
        onClick={() => !disabled && setState(r.value)}
      >
        <span
          className={`${styles.radiobutton} ${
            state === r.value ? styles.checked : ''
          }`}
        >
          <span className={styles.radiobuttonDot}></span>
        </span>
        <span className="f5">{r.label}</span>
      </div>
    ))}
  </div>
)
