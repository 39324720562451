import { getContainer, postRegister } from 'api/fetch'
import { getParams, storeCredentials } from 'functions/general'
import { useMainStore } from 'state/main'

export const getContainerQrFromUrl = (url: string) => {
  const urlArray = url?.split('/')

  return urlArray && urlArray.length >= 4 && urlArray[3] === 'c'
    ? urlArray[4]
    : null
}

export const getUserQrFromUrl = (url: string) => {
  return getParams(url, 'register')
}

export const addContainerToContainersState = (url: string) => {
  const { addContainer, containers, setError } = useMainStore.getState()

  const containerQr = getContainerQrFromUrl(url)

  // Check if container qr has integrity
  if (!containerQr) {
    setError('Gjenkjenner ikke beholder QR koden. Vennligst prøv en annen QR.')
    return new Promise<boolean>((res) => res(false))
  }
  // If container is already in global state - cancel ( else multiple api request for one qr )
  if (containerQr && !containers?.get(containerQr)) {
    return getContainer(containerQr)
      .then(addContainer)
      .then(
        () => true,
        (err) => {
          console.error('Problemer med å laste inn beholderen', err)
          setError('Problemer med å laste inn beholderen')
          return new Promise<boolean>((res) => res(false))
        }
      )
  } else {
    return new Promise<boolean>((res) => res(false))
  }
}

export const addQrUserToGlobalState = (url: string) => {
  const { credentials, setError } = useMainStore.getState()

  const userQrStr = getUserQrFromUrl(url)

  if (!userQrStr && !credentials) {
    setError('Gjenkjenner ikke beholder QR koden. Vennligst prøv en annen QR.')
    return new Promise<boolean>((res) => res(false))
  } else {
    return postRegister(userQrStr!)
      .then(storeCredentials)
      .then(
        () => true,
        (err) => {
          console.error('Problemer med å laste inn brukeren', err)
          setError('Problemer med å laste inn brukeren')
          return new Promise<boolean>((res) => res(false))
        }
      )
  }
}
