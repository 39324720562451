import { useEffect, useState } from 'react'

/**
 *
 * @param mediaWidth ex. '(min-width: 960px)'
 * @returns Returns true when condition matches
 */
export const useMatchMedia = (mediaWidth: string) => {
  const [match, setMatch] = useState<boolean>(false)

  useEffect(() => {
    const media = window.matchMedia(mediaWidth)
    const listener = () => setMatch(media.matches)
    listener()
    window.addEventListener('resize', listener)

    return () => window.removeEventListener('resize', listener)
  }, [match, mediaWidth])

  return match
}
