import { putDamagedContainer } from 'api/fetch'
import { BottomSection } from 'components/BottomSection/BottomSection'
import { ContainerHandlerList } from 'components/ContainerHandlerList/ContainerHandlerList'
import { Loading } from 'components/Loading/Loading'
import { RadioGroup } from 'components/Radio/Radio'
import { Title } from 'components/Title/Title'
import { clearState } from 'functions/general'
import { useState } from 'react'
import { useMainStore } from 'state/main'
import { match } from 'ts-pattern'
import { IPage } from 'types/page'
import { filesToFileArray } from 'utils/files'
import { IDamageDispatchString } from './functions'

export const OverviewDamage = ({
  route,
  returnPage,
  cancelPage,
}: {
  route: IPage
  returnPage: IPage
  cancelPage: IPage
}) => {
  const mainStore = useMainStore()
  const {
    setPage,
    setError,
    regType,
    containers,
    damageState,
    setDamageState,
  } = mainStore
  const [loading, setLoading] = useState<boolean>(false)

  const dispatchStr: IDamageDispatchString = (actionType) => (value) =>
    setDamageState({ type: actionType, payload: value })

  const hasContainers = () => !!containers?.size

  console.log('running', damageState)

  const confirm = () => {
    setLoading(true)
    return match({
      regTypeSet: !!regType,
      damageSet: !!damageState.damage?.length,
      hasContainers: hasContainers(),
    })
      .with({ regTypeSet: false }, () => {
        setError('Problemer med å finne registreringstypen')
        setLoading(false)
      })
      .with({ damageSet: false }, () => {
        setError('Vennligst velg et avvik')
        setLoading(false)
      })
      .with({ hasContainers: false }, () => {
        setError('Problemer med å finne beholder')
        setLoading(false)
      })
      .otherwise(() =>
        regType
          ? putDamagedContainer({
              damage_id: parseInt(damageState.damage!),
              damage_message: damageState.desc,
              image_urls: filesToFileArray(damageState.files),
            })
              .then(() => {
                clearState()
                setLoading(false)
                setPage(route)
              })
              .catch((err) => {
                console.error(err)
                setError('Problemer med å fullføre registreringen. ' + err)
                setLoading(false)
              })
          : null
      )
  }

  const cancel = () => {
    clearState()
    setLoading(false)
    setPage(cancelPage)
  }

  return (
    <div className="appView cols">
      <div className="view">
        <div className="rows lg">
          <Title space="sm2">Beholder</Title>
          <ContainerHandlerList removePermission={false} />
        </div>
        <div className="rows lg">
          <Title space="sm2">Hvilket type avvik?</Title>
          <div className="rows rows-xs md">
            <RadioGroup
              radios={[
                { label: 'Skitten', value: '1' },
                { label: 'Bulket', value: '2' },
                { label: 'Annet', value: '3' },
              ]}
              state={damageState.damage}
              setState={dispatchStr('damage')}
              disabled={loading}
            />
            <input
              type="text"
              onChange={(e) => dispatchStr('desc')(e.target.value)}
              disabled={loading}
              style={
                damageState.damage !== '3' ? { display: 'none' } : undefined
              }
              value={damageState.desc || ''}
            />
          </div>
        </div>
        <div className="rows">
          <div className="rows rows-xs xs">
            <h2 className="f4">Last opp bilder</h2>
          </div>
          <label id="fileUploadWidget">
            <input
              type="file"
              name="images"
              multiple={true}
              hidden
              disabled={loading}
              onChange={(e) => {
                const fileList = e.target.files
                if (fileList && fileList.length > 5) {
                  setError('Du kan maks laste opp 5 bilder')
                  return
                }
                setDamageState({ type: 'files', payload: e.target.files })
              }}
            />
            <div className="rows rows-xs xs">
              <p
                className="f6 text-fucales"
                style={{
                  fontWeight: 400,
                  textDecoration: 'underline',
                  visibility: loading ? 'hidden' : 'visible',
                }}
              >
                {damageState.filesCount === 1
                  ? `1 fil valgt.`
                  : damageState.filesCount
                  ? `${damageState.filesCount} filer valgt`
                  : 'Ingen filer valgt'}
              </p>
              <p className="f8">
                <i>(Maks 5 bilder)</i>
              </p>
            </div>
          </label>
          <div style={{ display: loading ? `block` : `none` }}>
            <Loading />
          </div>
        </div>
      </div>

      <BottomSection>
        <div className="rows nospace">
          <button className="w100" onClick={confirm} disabled={loading}>
            Fullfør
          </button>
          <button
            className="w100"
            onClick={() => setPage(returnPage)}
            disabled={loading}
          >
            Registrer flere beholdere
          </button>
          <button
            className="w100 secondary"
            onClick={cancel}
            disabled={loading}
          >
            Avbryt registrering
          </button>
        </div>
      </BottomSection>
    </div>
  )
}

/* <Title>Ordre</Title>
<ContainerHandlerList /> */
