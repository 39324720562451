import { getFillings } from 'api/fetch'
import { BottomSection } from 'components/BottomSection/BottomSection'
import { ContainerHandlerList } from 'components/ContainerHandlerList/ContainerHandlerList'
import { Input } from 'components/Input/Input'
import { Title } from 'components/Title/Title'
import { clearState } from 'functions/general'
import React, { useEffect, useMemo, useState } from 'react'
import { useMainStore } from 'state/main'
import { match } from 'ts-pattern'
import { IPage } from 'types/page'
import { getOverviewApiFunc, getOverviewSetup } from './configuration'

export const Overview = ({
  route,
  returnPage,
  cancelPage,
}: {
  route: IPage
  returnPage: IPage
  cancelPage: IPage
}) => {
  const mainStore = useMainStore()
  const { setPage, setError, regType, containers } = mainStore
  const [fillOptions, setFillOptions] =
    useState<{ id: string; name: string }[]>()

  useEffect(() => {
    if (regType === 'FILL') {
      getFillings()
        .then((fs) => fs?.map((f) => ({ id: f.id.toString(), name: f.name })))
        .then(setFillOptions)
    }
  }, [regType])

  const comps = useMemo(
    () => (regType && regType !== 'DAMAGE' ? getOverviewSetup(regType) : null),
    [regType]
  )

  const hasContainers = () => !!containers?.size

  const requiredInputsAreSet = () =>
    comps?.components.every((c) =>
      c.component.required
        ? 'state' in c.component
          ? !!mainStore[c.component.state]
            ? true
            : false
          : true
        : true
    ) || false

  const confirm = () =>
    match({
      regTypeSet: !!regType,
      requiredAreSet: requiredInputsAreSet(),
      hasContainers: hasContainers(),
    })
      .with({ regTypeSet: false }, () =>
        setError('Problemer med å finne registreringstypen')
      )
      .with({ requiredAreSet: false }, () =>
        setError('Vennligst fyll ut alle påkrevde felter')
      )
      .with({ hasContainers: false }, () =>
        setError('Vennligst se til at det er beholdere i listen')
      )
      .otherwise(() =>
        regType && regType !== 'DAMAGE'
          ? getOverviewApiFunc(regType)
              .then(() => {
                clearState()
                setPage(route)
              })
              .catch((err) => {
                console.error(err)
                setError('Problemer med å fullføre registreringen. ' + err)
              })
          : null
      )

  const cancel = () => {
    clearState()
    setPage(cancelPage)
  }

  return (
    <div className="appView cols">
      <div className="view">
        {comps ? (
          comps.components.map((c, i) => (
            <React.Fragment key={`overview_${i}`}>
              <Title space="sm2">{c.title}</Title>
              {match(c.component)
                .with({ type: 'text' }, (_c) => (
                  <Input
                    {..._c}
                    state={mainStore[_c.state]}
                    setState={mainStore[_c.setState]}
                  />
                ))
                .with({ type: 'number' }, (_c) => (
                  <Input
                    {..._c}
                    state={mainStore[_c.state]}
                    setState={mainStore[_c.setState]}
                  />
                ))
                .with({ type: 'select' }, (_c) => (
                  <Input
                    {..._c}
                    state={mainStore[_c.state]}
                    setState={mainStore[_c.setState]}
                    options={fillOptions || []}
                  />
                ))
                .with({ type: 'containerHandlerList' }, (_c) => (
                  <ContainerHandlerList />
                ))
                .exhaustive()}
            </React.Fragment>
          ))
        ) : (
          <></>
        )}
      </div>
      <BottomSection>
        <div className="viewWithBottomContainer">
          <div className="rows sm2">
            {containers && containers.size > 0 ? (
              <button className="w100" onClick={confirm}>
                Fullfør
              </button>
            ) : (
              <></>
            )}
            <button
              className="w100 secondary"
              onClick={() => setPage(returnPage)}
            >
              Registrer flere beholdere
            </button>
          </div>
          <BottomSection noPadding>
            <div className="rows nospace">
              <button className="w100 clear" onClick={cancel}>
                Avbryt registrering
              </button>
            </div>
          </BottomSection>
        </div>
      </BottomSection>
    </div>
  )
}

/* <Title>Ordre</Title>
<ContainerHandlerList /> */
