type IInput = {
  state: string | null
  setState: (val: string) => void
  className?: string
  required?: boolean
} & (
  | {
      type: 'text' | 'number'
      min?: number
      max?: number
      step?: number
      name?: string
      inputMode?: 'decimal'
      placeholder?: string
    }
  | {
      type: 'select'
      options: { id: string; name: string }[]
      name?: string
    }
)

export const Input = ({ state, setState, className, ...props }: IInput) => {
  return (
    <>
      {props.type === 'select' ? (
        <select
          name={props.name}
          value={state || ''}
          onChange={(e) => setState(e.target.value)}
        >
          <option value="invalid">Velg</option>
          {props.options?.map((o, i) => (
            <option key={`select-` + i} value={o.id}>
              {o.name}
            </option>
          ))}
        </select>
      ) : (
        <input
          type={props.type}
          className={className}
          name={props.name}
          max={props.max}
          min={props.min}
          step={props.step}
          inputMode={props.inputMode}
          placeholder={props.placeholder}
          value={state || ''}
          required={props.required}
          onChange={(e) => setState(e.target.value)}
        />
      )}
    </>
  )
}
