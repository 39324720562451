import styles from './BottomSection.module.css'

export const BottomSection: React.FC<{ noPadding?: boolean }> = ({
  children,
  noPadding,
}) => {
  return (
    <div className={`bottomSection ${styles.bottomSectionContainer}`}>
      <div
        className={`${styles.bottomSection} ${
          noPadding ? styles.noPadding : ''
        }`}
      >
        {children}
      </div>
    </div>
  )
}
