import { match } from 'ts-pattern'

export type DamageState = {
  damage: string | undefined
  desc: string | undefined
  files: FileList | undefined | null
  filesCount: number | undefined
}

export type IDamageDispatchString = (
  actionType: 'damage' | 'desc'
) => (value: string | undefined) => void

export type DamageReducerAction =
  | {
      type: 'damage' | 'desc'
      payload: string | undefined
    }
  | {
      type: 'files'
      payload: FileList | undefined | null
    }

export const damageReducer = (
  state: DamageState,
  action: DamageReducerAction
): DamageState => {
  return match(action)
    .with({ type: 'damage' }, (a) => ({
      ...state,
      damage: a.payload,
    }))
    .with({ type: 'desc' }, (a) => ({
      ...state,
      desc: a.payload,
    }))
    .with({ type: 'files' }, (a) => ({
      ...state,
      files: a.payload,
      filesCount: a.payload?.length,
    }))
    .exhaustive()
}
