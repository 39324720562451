import { BottomSection } from 'components/BottomSection/BottomSection'
import { clearUser } from 'functions/general'
import { useMainStore } from 'state/main'
import { match } from 'ts-pattern'
import { IRegType } from 'types/general'

const MenuButtons = ({
  buttons,
}: {
  buttons: { text: string; regType: IRegType }[]
}) => {
  const { setPage, setRegType } = useMainStore()

  const menuNav = (regType: IRegType) => () => {
    setRegType(regType)
    setPage(regType === 'DAMAGE' ? '/camera/damage' : '/camera')
  }

  return (
    <>
      {buttons.map((bt, i) => (
        <button
          key={`menu_button_${i}`}
          className="w100"
          onClick={menuNav(bt.regType)}
        >
          {bt.text}
        </button>
      ))}
    </>
  )
}

const Distributor = () => (
  <MenuButtons
    buttons={[
      { text: 'Ordre', regType: 'DELIVERY' },
      { text: 'Tilbakelevering', regType: 'RETURN' },
    ]}
  />
)

const Supplier = () => (
  <MenuButtons
    buttons={[{ text: 'Registrer fylte beholdere', regType: 'FILL' }]}
  />
)

const QualityControl = () => (
  <MenuButtons
    buttons={[
      { text: 'Registrer rekondisjonerte beholdere', regType: 'WASH' },
      { text: 'Registrer avvik', regType: 'DAMAGE' },
    ]}
  />
)

const Reconditioner = () => (
  <MenuButtons
    buttons={[
      { text: 'Før rekondisjonering', regType: 'PRERECONDITION' },
      { text: 'Etter rekondisjonering', regType: 'WASH' },
      { text: 'Registrer avvik', regType: 'DAMAGE' },
    ]}
  />
)

export const Menu = () => {
  const { credentials } = useMainStore()
  return (
    <div className="appView">
      <div className="view text-center">
        <div className="rows lg">
          <h1>Meny</h1>
        </div>

        {match(credentials!.role)
          .with('distributor', () => <Distributor />)
          .with('supplier', () => <Supplier />)
          .with('qualitycontrol', () => <QualityControl />)
          .with('reconditioner', () => <Reconditioner />)
          .exhaustive()}
      </div>
      <BottomSection>
        <div className="rows nospace">
          <button className="w100 clear" onClick={clearUser}>
            Logg ut
          </button>
        </div>
      </BottomSection>
    </div>
  )
}
