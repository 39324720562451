import { ErrorPopup } from 'components/ErrorPopup/ErrorPopup'
import { Header } from 'components/Header/Header'
import { useAppViewScrollTop } from 'components/ScrollTop/ScrollTop'
import React, { useEffect } from 'react'
import { getCredentials } from './functions/general'
import { Navigation } from './Navigation'
import { useMainStore } from './state/main'

function App() {
  const ref = useAppViewScrollTop()

  const { credentials, setCredentials, error } = useMainStore()

  // Load credentials in beginning of app if present
  useEffect(() => {
    if (!credentials) {
      setCredentials(getCredentials())
    }
  }, [credentials, setCredentials])

  return (
    <div ref={ref} id="appView">
      {error ? <ErrorPopup /> : ''}
      <Header />
      <div>
        <Navigation />
        {/* <div className="rows middle">
          <h1>Rekondisjonering</h1>
          <QRCode
            value={`https://pafyll.com?register=b1eec643-49c8-4ba9-81fc-b546e42f8537`}
            renderAs="svg"
            size={200}
            level="Q"
          />
        </div>
        <br /> */}
      </div>
    </div>
  )
}

export default App
