import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { getCredentials, storeCredentials } from 'functions/general'
import { postRenew } from './fetch'

const scannerApiAxios = axios.create()

export const jwtHandler = <T extends any>(
  callback: () => Promise<AxiosResponse<T>>
) => {
  return callback()
    .then((res: AxiosResponse<T>) => res?.data)
    .catch((err: AxiosError) => {
      const res = { ...err.response }
      if (err?.response) {
        const orginalRequest = { ...err.config } as AxiosRequestConfig & {
          _reply: any
        }
        const credentials = getCredentials()

        if (res.status === 401 && !orginalRequest['_reply'] && credentials) {
          return (
            postRenew()
              .then(storeCredentials)
              .then((_creds) => ({
                ...orginalRequest,
                headers: {
                  ...orginalRequest.headers,
                  authorization: `Basic ${_creds.jwt}`,
                },
                _reply: true,
              }))
              // Rerun orginal request
              .then(scannerApiAxios)
              .then((res: AxiosResponse<T>) => res?.data)
              .catch(() => {
                throw new Error(res?.data?.error)
              })
          )
        } else {
          throw new Error(res?.data?.error)
        }
      } else {
        throw new Error(res?.data?.error)
      }
    })
}

/**
 * Scanner api jwt token renew
 * - JWT needs to be expired and renewed to remove customers no longer in database. But also for
 * safety reasons. Here is how it is implemented in frontend with axios:
 *
 * Steps:
 * 1. If scanner api route fails with http 401 (onyl used for jwt valitation in scanner api)
 * means that the jwt has expired of have som faulty.
 * 2. When this happens axios is set to ask for a new jwt with the token credentials user has
 * stored in localstorage.
 * 3. Then the original api call with be redone with the new jwt token provided.
 * 4. If fails, user will only be notified. No clearing credentials for user token safety if
 * some server error causing it.
 */

/* scannerApiAxios.interceptors.response.use(
  async (res) => res,
  async (err: AxiosError) => {
    if (err?.response) {
      const res = { ...err.response }
      const orginalRequest = { ...err.config } as AxiosRequestConfig & {
        _reply: any
      }
      const credentials = getCredentials()

      if (res.status === 401 && !orginalRequest['_reply'] && credentials) {
        const setOptions = useMainStore.getState().setOptions
        return (
          postRenew()
            .then(storeCredentials)
            .then((_creds) => ({
              ...orginalRequest,
              headers: { ...orginalRequest.headers, 'X-Token': _creds.jwt },
              _reply: true,
            }))
            // Rerun orginal request
            .then(scannerApiAxios)
            // Refetch options
            .then(fetchOptions)
            .then(setOptions)
            .catch(() => {})
        )
      }
      return Promise.reject(res.data.error)
    }
  }
) */

export { scannerApiAxios }
