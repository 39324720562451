import { useEffect, useRef } from 'react'
import { useMainStore } from 'state/main'

export const useAppViewScrollTop = () => {
  const page = useMainStore((state) => state.page)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (page) {
      document.getElementById('appView')?.scroll(0, 0)
    }
  }, [page])

  return ref
}
