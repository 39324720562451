export const Title: React.FC<{
  size?: 'f1' | 'f2' | 'f3' | 'f4' | 'f5' | 'f6' | 'f7' | 'f8'
  align?: 'left' | 'center' | 'right'
  weight?: 'strong'
  color?: 'carrot' | 'fucales' | 'realgrey-darkest'
  space?: 'xs' | 'sm2' | 'lg' | 'xl' | 'nospace'
  className?: string
}> = ({ children, size, align, weight, color, space, className }) => {
  return (
    <div className={`rows ${space || 'lg'} ${className}`}>
      <h1
        className={`${[size, align, weight, color].filter((a) => a).join(' ')}`}
      >
        {children}
      </h1>
    </div>
  )
}
