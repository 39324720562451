import { CameraBatch } from 'pages/Camera/CameraBatch'
import { CameraUser } from 'pages/Camera/CameraUser'
import { Completed } from 'pages/Camera/Completed'
import { Menu } from 'pages/Menus/Menu'
import { Overview } from 'pages/Overview/Overview'
import { OverviewDamage } from 'pages/OverviewDamage/OverviewDamage'
import { match, not } from 'ts-pattern'
import { useMainStore } from './state/main'

export const Navigation = () => {
  const { page: _page, credentials: _cred } = useMainStore()

  return match({
    page: _page,
    cred: _cred,
  })
    .with({ page: '/', cred: null }, () => (
      <CameraUser route="/menu" text="Skann bruker QR for å logge inn" />
    ))
    .with({ page: '/', cred: not(null) }, () => <Menu />)
    .with({ page: '/menu', cred: not(null) }, () => <Menu />)
    .with({ page: '/camera', cred: not(null) }, () => (
      <CameraBatch route="/overview" cancelRoute="/menu" />
    ))
    .with({ page: '/camera/damage', cred: not(null) }, () => (
      <CameraBatch route="/overview/damage" cancelRoute="/menu" />
    ))
    .with({ page: '/overview', cred: not(null) }, () => (
      <Overview route="/completed" returnPage="/camera" cancelPage={'/menu'} />
    ))
    .with({ page: '/overview/damage', cred: not(null) }, () => (
      <OverviewDamage
        route="/completed"
        returnPage="/camera/damage"
        cancelPage="/menu"
      />
    ))
    .with({ page: '/completed', cred: not(null) }, () => (
      <Completed route="/menu" />
    ))
    .otherwise(() => (
      <CameraUser route="/menu" text="Skann bruker QR for å logge inn" />
    ))
}
