import { BottomSection } from 'components/BottomSection/BottomSection'
import { ContainerHandlerList } from 'components/ContainerHandlerList/ContainerHandlerList'
import { QRScanner } from 'components/QRScanner/QRScanner'
import { addContainerToContainersState } from 'functions/camera/orkla'
import { clearState } from 'functions/general'
import { useMatchMedia } from 'functions/hooks'
import { useState } from 'react'
import { useMainStore } from 'state/main'
import { IPage } from 'types/page'

export const CameraBatch = ({
  route,
  cancelRoute,
}: {
  route: IPage
  cancelRoute: IPage
}) => {
  const { containers, setPage } = useMainStore()
  const [removeLastContainerState, setRemoveLastContainerState] =
    useState<string>()
  const tablet = useMatchMedia(
    '(orientation: landscape) and (min-width: 767px)'
  )
  const cancel = () => {
    clearState()
    setPage(cancelRoute)
  }

  return (
    <div className="appView cols">
      <div className="view">
        <div className="rows rows-sm2">
          <QRScanner
            qrResultFunction={addContainerToContainersState}
            removeLastContainerState={removeLastContainerState}
          />
        </div>
        <div className="rows rows-lg">
          <div className="rows rows-xs">
            <p className="f5 text-center">Skann en eller flere beholdere</p>
            <h1 className="f4 text-center">
              {containers?.size || 0}{' '}
              {containers && containers?.size === 1 ? 'beholder' : 'beholdere'}{' '}
              registrert
            </h1>
          </div>
          {!tablet ? (
            <ContainerHandlerList
              lastRemovedItem={setRemoveLastContainerState}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      <BottomSection>
        <div className="viewWithBottomContainer">
          <div className="rows nospace">
            {tablet ? (
              <>
                <h2 className="f5">Registrerte beholdere</h2>
                <ContainerHandlerList
                  lastRemovedItem={setRemoveLastContainerState}
                />{' '}
              </>
            ) : (
              <></>
            )}
          </div>
          <BottomSection noPadding>
            <div className="rows nospace">
              {containers && containers.size > 0 ? (
                <button className="w100" onClick={() => setPage(route)}>
                  Videre
                </button>
              ) : (
                <></>
              )}
              <button className="w100 clear" onClick={cancel}>
                Avbryt
              </button>
            </div>
          </BottomSection>
        </div>
      </BottomSection>
    </div>
  )
}
